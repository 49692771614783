define(['app'], function (app) {

  const productTags = function () {
    const component = {};

    const _config = {
      selectors: {
        component: '[data-component=productTags]',
        icons: '[data-product-tags-list]',
        tag: '.productTags_listItem'
      },
      subscribeChannels: {
        icons: 'productTags/new',
      },
      attrs: {
        formulation: 'data-formulation'
      }
    };

    const _init = (element, userDriven) => {
      component.element = element;
      component.parent = component.element.parentNode;
      component.formulation = component.element.getAttribute(component.config.attrs.formulation);
      component.context = component.parent.classList[1];
      component.tags = component.element.querySelectorAll(component.config.selectors.tags);

      if (!userDriven) {
        app.subscribe(component.config.subscribeChannels.icons, component.getNewTags);
      }

      return component;
    };

    const _getNewTags = (productId) => {
      let getUrl = `/${productId}.tags?context=${component.context}`;

      if (component.formulation === 'true') {
        getUrl += '&contentKey=beauty_formulation';
      }

      app.ajax.get({
        url: getUrl,
        success: component.tagsSuccessHandler,
        error: component.tagsErrorHandler,
      });
    };

    const _tagsSuccessHandler = (response) => {
      const parent = component.element.parentNode;
      parent.innerHTML = response;
      if (parent) {
        const newElement = parent.querySelector('[data-component=productTags]');
        component.init(newElement, true);
      }
    };

    const _tagsErrorHandler = () => {
      console.error('ERROR: Could not retrieve child product tags');
    };

    component.config = _config;
    component.init = _init;
    component.tagsSuccessHandler = _tagsSuccessHandler;
    component.getNewTags = _getNewTags;
    component.tagsErrorHandler = _tagsErrorHandler;

    return component;
  };

  return productTags;
});
